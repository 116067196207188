import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';

import FooterLinkRow from './footerLinkRow';
import SocialMediaLinks from './socialMediaLinks';
import { FormattedMessage } from 'react-intl';

import logo from '../../assets/img/logo.png';
import PropTypes from 'prop-types';


import '../../assets/scss/components/_footer.scss';


class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const links = {
      aboutLinks: [
        {
          text: 'imprint',
          to: `${this.props.activeLangKey}/imprint`
        },
        {
          text: 'privacyPolicy',
          to: `${this.props.activeLangKey}/privacy-policy`
        },
        {
          text: 'jobs',
          to: `${this.props.activeLangKey}/jobs`
        }
      ],
      pagesLinks: [
        {
          text: 'home',
          to: `${this.props.activeLangKey}/`
        },
        {
          text: 'product scouting',
          to: `${this.props.activeLangKey}/products/scouting`
        },
        {
          text: 'product analyse',
          to: `${this.props.activeLangKey}/products/analysis`
        },
        {
          text: 'aboutUs',
          to: `${this.props.activeLangKey}/about-us`
        },
        {
          text: 'media',
          to: `${this.props.activeLangKey}/media`
        },
        {
          text: 'contact',
          to: `${this.props.activeLangKey}/contact`
        }
      ],
      followUsLinks: [
        {
          text: 'fab fa-facebook-f',
          href: 'https://www.facebook.com/impectofficial/'
        },
        {
          text: 'fab fa-youtube',
          href: 'https://www.youtube.com/channel/UCiLIRHzhYob52AGDJPizaHA'
        },
        {
          text: 'fab fa-twitter',
          href: 'https://twitter.com/impect_official'
        },
        {
          text: 'fab fa-instagram',
          href: 'https://www.instagram.com/impect_official/'
        },
      ]
    };

    return (
      <footer className="footer-section">
        <Container
          className="px-5"
          fluid
        >
          <Row>
            <Col lg={3} md={6} className="g-mb-30">
              <Link className="d-inline-block mb-4" to="/">
                <img
                  className="logo"
                  src={logo}
                  alt="Impect Logo"
                />
              </Link>
              <p>
                <SocialMediaLinks links={links.followUsLinks} />
              </p>
              <p>
                {new Date().getFullYear()} © <FormattedMessage id="copyrightSentence"/>
              </p>
            </Col>

            <Col lg={3} sm={6} className="mb-30">
              <ul className="list-unstyled">
                <li className="media mb-4">
                  <div className="media-body">
                    Siegburger Straße 149-151
                    <br/>
                    50679 <FormattedMessage id="cologne"/>
                    <br/>
                    <FormattedMessage id="germany"/>
                  </div>
                </li>
                <li className="media mb-4">
                  <div className="media-body">
                    <a
                      href="mailto:info@impect.com"
                    >
                      info@impect.com
                    </a>
                  </div>
                </li>
                <li className="media mb-4">
                  <div className="media-body">
                    <a
                      href="tel:004922199992727"
                    >
                      +49 221 355 54 0
                    </a>
                  </div>
                </li>
              </ul>
            </Col>

            <Col lg={3} md={6}>
              <FooterLinkRow
                links={links.pagesLinks}
              />
            </Col>

            <Col lg={3} md={6}>
              <FooterLinkRow
                links={links.aboutLinks}
              />
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  activeLangKey: 'de'
};

Footer.propTypes = {
  activeLangKey: PropTypes.string
};

export default Footer;
