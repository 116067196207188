import React, { Component } from 'react';
import 'intl';
import { Container } from 'reactstrap';
import { Link } from 'gatsby';

import SocialLinks from './socialLinks';


import '../assets/scss/pages/_contentPage.scss';
import Img from 'gatsby-image';

class contentPage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { headerImage, header, preHeader, content, contentHTML, align, backLink } = this.props;

    return (
      <div className="content-wrapper">
        <section className="intro text-center">
          <div
            className="position-absolute social-links-wrapper"
          >
            <SocialLinks/>
          </div>
          <div className={`info-text aline-${align}`}>
            {backLink ? (
              <Link className="back-link" to={backLink}>
                <i className="fas fa-angle-double-left" />
                {' '}
                Back to list
              </Link>
            ) : null}
            <h1>
            <span>
              {preHeader}
            </span>
              {header}
            </h1>
          </div>
          {headerImage ? (
            <Img
              className='img-fluid'
              style={{ position: 'initial' }}
              fixed={headerImage}
            />
          ) : null}
        </section>

        <section className="content-section oblique">
          {contentHTML ? (
            <Container className="oblique-content" dangerouslySetInnerHTML={{ __html: contentHTML }}/>
          ) : (
            <Container className="oblique-content">
              {content}
            </Container>
          )}
        </section>
      </div>
    );
  }
}

export default contentPage;
