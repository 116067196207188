module.exports = {
  about: 'About',
  aboutUs: 'About us',
  cologne: 'Cologne',
  contact: 'Contact',
  copyrightSentence: 'All Rights Reserved.',
  de: 'German',
  en: 'English',
  followUs: 'follow us',
  germany: 'Germany',
  home: 'Home',
  imprint: 'Imprint',
  jobs: 'Jobs',
  media: 'Media',
  privacyPolicy: 'Privacy policy',
  products: 'Products',
  'product analyse': 'Impect Analyse',
  'product scouting': 'Impect Scouting',
  jobsLookingFor: 'Didn\'t find what you were looking for?',
  contactUs: 'Contact us',
  readMore: 'Read more',
  recommend: 'Recommend',
  applicationTo: 'Application to',
  applicationInfo: 'If this fits you please send you application via email to <a href="mailto:inof@impect.com">inof@impect.com</a> or click on "apply now"',
  applyNew: 'Apply Now',
  learnMore: 'Learn more',
  contactAddress: "Address:",
  contactEmail: "Email:",
  contactPhoneNumber: "Phone number:",
  required: "This is required",
  invalidEmail: "It looks like that's not an email address",
  "placeholder-name": "Name",
  "placeholder-phone": "Phone",
  "placeholder-email": "Email",
  "placeholder-message": "Message",
  sendMessage: "Send message",
  successfulSend: "Many thanks for contacting us.",
  somethingIsWrong: "Oh something didn't work, please try again later.",

};
