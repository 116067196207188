import React, { Component } from 'react';
import { Link, navigate } from 'gatsby';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ReactFlagsSelect from 'react-flags-select';
import * as _ from 'lodash';

import '../assets/scss/components/_languageSelect.scss';
import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
import '../assets/scss/components/_header.scss';
import logo from '../assets/img/logo-black.png';
import PropTypes from 'prop-types';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      menu: []
    };

    this.langFlags = {
      'en': 'GB',
      'de': 'DE'
    };

    this.countries = [
      'GB',
      'DE'
    ];

    this.customLabels = {
      'GB': <FormattedMessage id={'en'}/>,
      'DE': <FormattedMessage id={'de'}/>
    };

    this.makeMenuLinks = this.makeMenuLinks.bind(this);
    this.chanceLang = this.chanceLang.bind(this);
    this.toggle = this.toggle.bind(this);
    this.makeMenuLinks = this.makeMenuLinks.bind(this);
  }

  chanceLang(selectedLang) {
    const langMappings = {
      'GB': 'en',
      'DE': 'de'
    };

    const findLang = _.find(this.props.langs, { langKey: langMappings[selectedLang] });
    navigate(findLang.link);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  makeMenuLinks(items) {
    const links = [];

    for ( let i = 0; i < items.length; i++ ) {
      const item = items[i];

      if (item.links) {
        const subNavLinks = [];

        for ( let i = 0; i < item.links.length; i++ ) {
          const subItem = item.links[i];
          subNavLinks.push(
            <DropdownItem
              tag={Link}
              to={subItem.to}
              className={`${item.linkClass} text-center my-3 my-lg-0`}
              activeClassName="active"
            >
              <FormattedMessage id={subItem.text}/>
            </DropdownItem>
          );
        }

        links.push(
          <UncontrolledDropdown
            nav
            className={`${item.linkClass || ''} text-center my-3 my-lg-0`}
            inNavbar>
            <DropdownToggle
              nav
              caret>
              <FormattedMessage id={item.text}/>
              <i className="fas fa-angle-down" />
            </DropdownToggle>
            <DropdownMenu
              className='hs-sub-menu list-unstyled animated fadeIn'
              right>
              {subNavLinks}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      } else {
        links.push(
          <NavItem
            key={i}
            className={`${item.linkClass || ''} text-center my-3 my-lg-0`}
          >
            {item.to ? (
              <Link
                to={item.to}
                activeClassName="active"
              >
                <FormattedMessage id={item.text}/>
              </Link>
            ) : null}
          </NavItem>
        );
      }
    }
    return links;
  }

  render() {
    const links = [
      {
        text: 'home',
        to: `/${this.props.activeLangKey}/`
      },
      {
        text: 'products',
        links: [
          {
            text: 'product scouting',
            to: `/${this.props.activeLangKey}/products/scouting`
          },
          {
            text: 'product analyse',
            to: `/${this.props.activeLangKey}/products/analysis`
          }
        ]
      },
      {
        text: 'aboutUs',
        to: `/${this.props.activeLangKey}/about-us`
      },
      {
        text: 'media',
        to: `/${this.props.activeLangKey}/media`
      }
    ];

    const links2 = [
      {
        text: 'contact',
        linkClass: "tag",
        to: `/${this.props.activeLangKey}/contact`
      }
    ];

    return (
      <header
        className="header-section u-header u-header--sticky-top u-header--toggle-section u-header--change-appearance"
        style={{ boxShadow: '0 3px 11px -7px #000' }}
      >
        <div className="u-header__section u-header__section--light">
          <Navbar fixed="top" expand="lg">
            <Container
              className="px-0 px-md-5"
              fluid
            >
              <NavbarToggler
                onClick={this.toggle}
                className="navbar-toggler navbar-toggler-right btn"
              >
                <span
                  className={
                    'hamburger hamburger--slider ' +
                    ( !this.state.isOpen ? '' : 'is-active' )
                  }
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"/>
                  </span>
                </span>
              </NavbarToggler>
              <NavbarBrand tag={Link} to={`/de/`}>
                <img
                  src={logo}
                  alt="Impect Logo"
                  className="logo"
                />
              </NavbarBrand>


              <Collapse
                className="justify-content-center"
                isOpen={this.state.isOpen}
                navbar
              >
                <Nav
                  className="ml-auto"
                  navbar
                >
                  {this.makeMenuLinks(links)}
                </Nav>

                <Nav
                  className="ml-auto"
                  navbar
                >
                  <NavItem
                    className="text-center mr-0 mr-md-5  selected-flag-wrapper"
                  >
                    <ReactFlagsSelect
                      defaultCountry={this.langFlags[this.props.activeLangKey]}
                      countries={this.countries}
                      customLabels={this.customLabels}
                      showSelectedLabel={true}
                      selectedSize={14}
                      optionsSize={12}
                      alignOptions="left"
                      onSelect={this.chanceLang}
                      className="pt-2"
                    />

                    <i className="fas fa-angle-down" />
                  </NavItem>
                  {this.makeMenuLinks(links2, "badge-pill badge-primary")}
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  activeLangKey: 'de'
};

Header.propTypes = {
  activeLangKey: PropTypes.string
};

export default Header;
