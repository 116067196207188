import React, { Component } from 'react'

import '../assets/scss/components/_socialLinks.scss';

class SocialLinks extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const followUsLinks = [
      {
        text: 'fab fa-twitter',
        href: 'https://twitter.com/impect_official'
      },
      {
        text: 'fab fa-youtube',
        href: 'https://www.youtube.com/channel/UCiLIRHzhYob52AGDJPizaHA'
      },
      {
        text: 'fab fa-facebook-f',
        href: 'https://www.facebook.com/impectofficial/'
      },
      {
        text: 'fab fa-instagram',
        href: 'https://www.instagram.com/impect_official/'
      },

    ];
    const links = [];

    for ( let i = 0; i < followUsLinks.length; i++ ) {
      const link = followUsLinks[i];
      let spanClass = 'mx-3';

      if (i === 0) {
        spanClass = 'mr-3';
      }


      links.push(
        <span className={'social-link ' + spanClass} key={i}>
          {link.href ? (
            <a href={link.href} target="_blank">
              <i className={link.text}/>
            </a>
          ) : null}
        </span>
      );
    }

    return (
      <div className="social-links">
        {links}
      </div>
    );
  }
}

export default SocialLinks;
