import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FooterLinkRow extends Component {
  constructor(props) {
    super(props);

    this.makeMenuLinks = this.makeMenuLinks.bind(this);
  }

  makeMenuLinks() {
    const links = [];

    for ( let i = 0; i < this.props.links.length; i++ ) {
      const link = this.props.links[i];
      let spanClass = 'mx-4';

      if (i === 0) {
        spanClass = 'mr-4'
      }


      links.push(
        <span className={spanClass} key={i}>
          {link.href ? (
            <a href={link.href} target="_blank">
              <i className={link.text}/>
            </a>
          ) : null}
        </span>
      );
    }
    return links;
  }

  render() {
    return (
      <div className="mt-2 mb-5">
        {this.props.links ? this.makeMenuLinks(this.props.links) : null}
      </div>
    );
  }
}

FooterLinkRow.propTypes = {
  header: PropTypes.any,
  links: PropTypes.array,
  margin: PropTypes.string,
};

export default FooterLinkRow;
