module.exports = {
  about: 'Über Impect',
  aboutUs: 'Über uns',
  cologne: 'Köln',
  contact: 'Kontakt',
  copyrightSentence: 'All Rights Reserved.\n',
  de: 'Deutsch',
  en: 'Englisch',
  followUs: 'Folge uns',
  germany: 'Deutschland',
  home: 'Home',
  imprint: 'Impressum',
  jobs: 'Jobs',
  media: 'Medien',
  privacyPolicy: 'Datenschutz',
  products: 'Produkte',
  'product analyse': 'Impect Analyse',
  'product scouting': 'Impect Scouting',
  jobsLookingFor: 'Nicht gefunden, was du gesucht hast?',
  contactUs: 'Kontaktiere uns',
  readMore: 'Mehr erfahren',
  recommend: 'Weiterempfehlen',
  applicationTo: 'Bewerbung bei',
  applicationInfo: 'Wenn dies für Sie zutrifft, senden Sie bitte Ihre Bewerbung per E-Mail an <a href="mailto:inof@impect.com">inof@impect.com</a> oder klicken Sie auf "Jetzt bewerben".',
  applyNew: 'Jetzt bewerben',
  learnMore: 'Mehr erfahren',
  contactAddress: "Address:",
  contactEmail: "E-Mail:",
  contactPhoneNumber: "Telefonnummer:",
  required: "Das ist erforderlich",
  invalidEmail: "Es sieht so aus, als wäre das keine E-Mail-Adresse",
  "placeholder-name": "Name",
  "placeholder-phone": "Telefonnummer",
  "placeholder-email": "E-Mail-Adresse",
  "placeholder-message": "Nachricht",
  sendMessage: "Nachricht senden",
  successfulSend: "Vielen Dank für Ihre Kontaktaufnahme.",
  somethingIsWrong: "Oh da stimmt was nicht, bitte versuchen sie es später noch mal.",
};
