import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';

class FooterLinkRow extends Component {
  constructor(props) {
    super(props);

    this.makeMenuLinks = this.makeMenuLinks.bind(this);
  }

  makeMenuLinks() {
    const links = [];

    for ( let i = 0; i < this.props.links.length; i++ ) {
      const link = this.props.links[i];
      let liClass = 'my-3';

      if (i === 0) {
        liClass = 'mt-0 mb-3';
      }

      links.push(
        <li key={i} className={liClass}>
          {link.to ? (
            <Link to={link.to}>
              <FormattedMessage id={link.text}/>
            </Link>
          ) : null}
          {link.href ? (
            <a href={link.href} target="_blank">
              <FormattedMessage id={link.text}/>
            </a>
          ) : null}
        </li>
      );
    }
    return links;
  }

  render() {
    return (
      <ul className="list-unstyled">
        {this.props.links ? this.makeMenuLinks(this.props.links) : null}
      </ul>
    );
  }
}

FooterLinkRow.propTypes = {
  header: PropTypes.any,
  links: PropTypes.array
};

export default FooterLinkRow;
