import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { IntlProvider, addLocaleData } from 'react-intl';
import 'intl';

import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';
import de from 'react-intl/locale-data/de';
import 'intl/locale-data/jsonp/de';

import Header from './header'
import Footer from './footer'

import '../assets/scss/theme.scss';

// add concatenated locale data
addLocaleData([
  ...de,
  ...en
]);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.children = this.props.children;
    const data = this.props.data;
    const location = this.props.location;
    const url = location.pathname;
    const { langs, defaultLangKey } = data.site.siteMetadata.languages;
    this.langKey = getCurrentLangKey(langs, defaultLangKey, url);
    this.homeLink = `/${this.langKey}/`;
    this.langsMenu = getLangs(langs, this.langKey, getUrlForLang(this.homeLink, url));

    // get the appropriate message file based on langKey
    // at the moment this assumes that langKey will provide us
    // with the appropriate language code
    this.i18nMessages = require(`../../data/messages/${this.langKey}`);
  }

  render() {
    return (
      <IntlProvider
        locale={this.langKey}
        messages={this.i18nMessages}
      >
        <>
          {this.props.displayHeader ? <Header langs={this.langsMenu} activeLangKey={this.langKey} /> : null}
          <main>
            {this.children}
          </main>
          {this.props.displayFooter ? <Footer activeLangKey={this.langKey} /> : null}
        </>
      </IntlProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  displayHeader: PropTypes.bool,
  displayFooter: PropTypes.bool,
};

Layout.defaultProps = {
  displayHeader: true,
  displayFooter: true,
};

export default Layout;
